import { useState } from "react";
import {
  AppStoreWhiteIcon,
  DevelopersExploreIcon,
  DevelopersUseIcon,
  LeftPointerIcon,
  PlayStoreWhiteIcon,
  RightPointerIcon,
  ZapierIcon,
  NotionIcon,
  DropBoxIcon,
  MailChimpIcon,
  GDriveIcon,
  JiraIcon,
  TrelloIcon,
  TwitterIcon,
  TelegramIcon,
  CPIcon,
  WhatsappIcon,
  TasksIcon,
  FacebookIcon,
  GmailIcon,
  SlackIcon,
  FigmaIcon,
  LinkedInIcon,
  EnifLogo,
  AutomatedOperationIcon,
  AutomatedSupportIcon,
  NaturalEvolutionIcon,
} from "../../assets/icons";
import TrainEnif from "../../assets/images/train-enif.svg";
import BigCommerce from "../../assets/images/bigcommerce.svg";
import WooCommerce from "../../assets/images/woocommerce.svg";
import Magento from "../../assets/images/magento3.svg";
import OpenCart from "../../assets/images/opencart.svg";
import Wix from "../../assets/images/wix.svg";
import Shopify from "../../assets/images/shopify.svg";
import Ditched from "../../assets/images/ditched-scripted2.png";
// import Support from "../../assets/images/247 support2.svg";
import Support from "../../assets/images/247 support2.png"
import LimitedChatBots from "../../assets/images/limited-chatbots.svg";
import SmoothHandoff from "../../assets/images/smooth-handoff.svg";
import styles from "./Platform.module.css";

const Platform = () => {
  return (
    <div className={styles["platform"]}>
      <section id={styles["section-1"]}>
        <div className={styles["top"]}>
          <div>
            <div className={styles["top-top"]}>
              <div className={styles["left"]}>
                <h2 className={styles["header"]}>Smarter Customer Support</h2>
                <p className={styles["description"]}>Enif AI vs Chatbots</p>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>Get Started</button>
                </a>
              </div>
              <div className={styles["right"]}>
                <img src={Support} alt="Smarter Customer Support" />
              </div>
            </div>
            <div className={styles["cms"]}>
              <div className={styles["cms-items"]}>
                <img src={BigCommerce} alt="BigCommerce" />
                <img src={WooCommerce} alt="WooCommerce" />
                <img src={Magento} alt="Magento" />
                <img src={OpenCart} alt="OpenCart" />
                <img src={Wix} alt="Wix" />
                <img src={Shopify} alt="Shopify" />
              </div>
            </div>
          </div>
          <div>
            <div className={styles["left"]}>
              <h2 className={styles["header"]}>
                Ditch the Script, Get Smart Support
              </h2>
              <p className={styles["description"]}>
                Enif AI is more than a chatbot. It understands your business,
                products, and policies to deliver personalized solutions for
                each customer. (Highlights personalization and unique value
                proposition)
              </p>
              <a
                href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
                target="_blank"
                rel="noreferrer"
              >
                <button>Learn more</button>
              </a>
            </div>
            <div className={styles["right"]}>
              <img src={Ditched} alt="Ditch Scripted Chatbots" />
            </div>
          </div>
          <div>
            <div className={styles["left"]}>
              <h2 className={styles["header"]}>
                Limited Chatbots, Limitless Enif
              </h2>
              <p className={styles["description"]}>
                Traditional chatbots struggle with complex questions. Enif uses
                AI to analyze each situation and find the best solution, drawing
                from your knowledge base, customer data, and more.
              </p>
              <a
                href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
                target="_blank"
                rel="noreferrer"
              >
                <button>Learn more</button>
              </a>
            </div>
            <div className={styles["right"]}>
              <img
                src={LimitedChatBots}
                alt="Limited Chatbots, Limitless Enif"
              />
            </div>
          </div>
          <div>
            <div className={styles["left"]}>
              <h2 className={styles["header"]}>
                Smooth Handoff to Human Support
              </h2>
              <p className={styles["description"]}>
                If Enif ever needs help, it seamlessly connects your customer to
                a live agent. You're always in control.
              </p>
              <a
                href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
                target="_blank"
                rel="noreferrer"
              >
                <button>Learn more</button>
              </a>
            </div>
            <div className={styles["right"]}>
              <img src={SmoothHandoff} alt="Smooth Handoff to Human Support" />
            </div>
          </div>
        </div>
        <div className={styles["bottom"]}></div>
      </section>
      <section className={styles["section7"]}>
        <div className={styles["left"]}>
          <h2 className={styles["header"]}>
            Train Enif Like a New Hire (It's Easy!)
          </h2>
          <p className={styles["description"]}>
            Set up Enif in minutes, no coding required. Train it on your
            knowledge base, and it'll tackle inquiries like a pro in no time.
          </p>
          <a
            href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
            target="_blank"
            rel="noreferrer"
          >
            <button>Learn more</button>
          </a>
        </div>
        <div className={styles["right"]}>
          <img src={TrainEnif} alt="Uncover Hidden Insights" />
        </div>
      </section>
      <section className={styles["section8"]}>
        <div className={styles["top"]}>
          <div className={styles["section8Header"]}>
            <h2>Automate your customer service with Enif today</h2>
          </div>
          <div className={styles["section8Description"]}>
            <p>
              Onboard, measure and coach your AI agent — like you would a human
              — and start resolving more inquiries, with less effort.
            </p>
          </div>
          <div className={styles["section8Buttons"]}>
            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["get-started-btn"]}
            >
              Get Started
            </a>

            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["learn-more-btn"]}
            >
              Learn More
            </a>
          </div>
        </div>
        <div className={styles["bottom"]}></div>
      </section>
    </div>
  );
};

export default Platform;
