import styles from "./Pricing.module.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PricingCards from "../../components/PricingCard/PricingCards";

const Pricing = () => {
  const propositions = [
    {
      title: "Streamline<br/> Workflows",
      desc: "Organize and prioritize inquiries with multi-agent/department support, ticket management (escalation, assignment, processing tiers, auto-closure), and live chat/messaging integration.",
    },
    {
      title: "Boost Efficiency",
      desc: "Respond faster with automated live chat support, channel escalation (Slack & Chat), and customer-centric text correction.",
    },
    {
      title: "Deliver Exceptional<br/> Service",
      desc: "Meet customer expectations with customizable SLAs, multi-level escalations, and time-based rules.",
    },
    {
      title: "Gain Valuable<br/> Insights",
      desc: "Track customer interactions and sentiment with product engagement analysis and customer sentiment analysis.",
    },
    {
      title: "Run Targeted<br/> Campaigns",
      desc: "Deliver personalized campaigns and product recommendations across email, SMS, and in-app messaging.",
    },
  ];
  const ticketManagement = [
    {
      title: "Manual and Automatic<br/> Escalation",
      desc: "Collaborate seamlessly across teams for efficient query resolution.",
    },
    {
      title: "Automated Assignment<br/> and Processing",
      desc: "Streamline ticket handling for faster resolution.",
    },
    {
      title: "Ticket Processing Tiers",
      desc: "Categorize and manage tickets based on priority levels.",
    },
    {
      title: "Auto-Closed Tickets",
      desc: "Utilize AI to close resolved tickets efficiently.",
    },
  ];
  const LCMI = [
    {
      title: "Social Media Integration<br/> (WhatsApp)",
      desc: "Engage with customers via popular messaging platforms.",
    },
    {
      title: "Live Chat via Mobile<br/> App SDK and Website Plugin",
      desc: "Offer real-time support across multiple channels.",
    },
    {
      title: "Multi-Channel Messaging",
      desc: "Seamlessly communicate with customers across various platforms.",
    },
    {
      title: "Automated Live Chat<br/> Support",
      desc: "Enhance customer service with automated responses and routing.",
    },
    {
      title: "Escalation to Channels<br/> (Slack & Chat)",
      desc: "Escalate queries to relevant channels for swift resolution.",
    },
  ];
  const CCS = [
    {
      title: "Customer-Centric Text<br/> Corrector",
      desc: "Ensure accurate and clear communication with customers.",
    },
    {
      title: "Service Level Agreements (SLAs) and Multi-Level<br/> Escalations",
      desc: "Set and meet customer service standards with customizable SLAs and escalation protocols.",
    },
    {
      title: "Time-Based Rules",
      desc: " Automate responses and actions based on predefined timeframes.",
    },
  ];
  const MAF = [
    {
      title: "Product Engagement<br/> Analysis",
      desc: "Gain insights into customer interaction with your products.",
    },
    {
      title: "Customer Sentiment<br/> Analysis",
      desc: "Understand customer perceptions and feedback.",
    },
    {
      title: "Product Campaigns Via<br/> Multiple Channels",
      desc: "Reach customers through SMS, email, and in-app messaging.",
    },
    {
      title: "Automated Email Support<br/> and AI Personalized<br/> Campaigns",
      desc: "Enhance marketing efforts with automated email responses and personalized campaigns.",
    },
  ];

  const items = [
    {
      title: "Streamline Workflows",
      desc: "Organize and prioritize inquiries with multi-agent/department support, ticket management (escalation, assignment, processing tiers, auto-closure), and live chat/messaging integration.",
    },
    {
      title: "Boost Efficiency",
      desc: "Respond faster with automated live chat support, channel escalation (Slack & Chat), and customer-centric text correction.",
    },
    {
      title: "Deliver Exceptional Service",
      desc: "Meet customer expectations with customizable SLAs, multi-level escalations, and time-based rules.",
    },
    {
      title: "Gain Valuable Insights",
      desc: "Track customer interactions and sentiment with product engagement analysis and customer sentiment analysis.",
    },
    {
      title: "Run Targeted Campaigns",
      desc: "Deliver personalized campaigns and product recommendations across email, SMS, and in-app messaging.",
    },
    {
      title: "Multi-Agent & Multi-Department Support",
      desc: "Collaborate seamlessly across teams for efficient query resolution.",
    },
    {
      title: "Manual and Automatic Escalation",
      desc: "Collaborate seamlessly across teams for efficient query resolution.",
    },
    {
      title: "Automated Assignment and Processing",
      desc: "Streamline ticket handling for faster resolution.",
    },
    {
      title: "Ticket Processing Tiers",
      desc: "Categorize and manage tickets based on priority levels.",
    },
    {
      title: "Auto-Closed Tickets",
      desc: "Utilize AI to close resolved tickets efficiently.",
    },
  ];
  return (
    <div className={styles["wrapper"]}>
      <section className={styles["section1"]}>
        <div className={styles["header"]}>
          <div className={styles["h2s"]}>
            <h2>Unlock Powerful</h2>
            <h2>Features with Enif</h2>
            <h2>Supercharge</h2>
          </div>
          <a href="/">Get Pricing</a>
        </div>
        <div className={styles["accordion-wrapper"]}>
          <div className={styles["accordion"]}>
            <div className={styles["accordion-header"]}>
              <h3>Focus on Value Propositions</h3>
              <div className={styles["circle"]}>↗</div>
            </div>
            <div className={styles["main"]}>
              {propositions.map((proposition) => (
                <div
                  className={styles["accordion-item"]}
                  key={proposition.title}
                >
                  {/* <p className={styles["title"]}>{proposition.title}</p> */}
                  <p
                    className={styles["title"]}
                    dangerouslySetInnerHTML={{ __html: proposition.title }}
                  />
                  <p>{proposition.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles["accordion-wrapper"]}>
          <div className={styles["accordion"]}>
            <div className={styles["accordion-header"]}>
              <h3>Enif Supercharge Feature</h3>
              <div className={styles["circle"]}>↗</div>
            </div>
            <div className={styles["main"]}>
              <div className={styles["accordion-item2"]}>
                <p className={styles["title"]}>
                  Multi-Agent & Multi-Department Support
                </p>
                <p>
                  Collaborate seamlessly across teams for efficient query
                  resolution.
                </p>
              </div>
              <div className={styles["main2"]}>
                <p className={styles["title"]}>Ticket Management</p>
                {ticketManagement.map((proposition) => (
                  <div
                    className={styles["accordion-item"]}
                    key={proposition["title"]}
                  >
                    {/* <p className={styles["title"]}>{proposition.title}</p> */}
                    <p
                      className={styles["title"]}
                      dangerouslySetInnerHTML={{ __html: proposition.title }}
                    />
                    <p>{proposition.desc}</p>
                  </div>
                ))}
              </div>
              <div className={styles["main2"]}>
                <p className={styles["title"]}>
                  Live Chat and Messaging Integration
                </p>
                {LCMI.map((lcmi) => (
                  <div className={styles["accordion-item"]} key={lcmi["title"]}>
                    {/* <p className={styles["title"]}>{lcmi.title}</p> */}
                    <p
                      className={styles["title"]}
                      dangerouslySetInnerHTML={{ __html: lcmi.title }}
                    />
                    <p>{lcmi.desc}</p>
                  </div>
                ))}
              </div>
              <div className={styles["main2"]}>
                <p className={styles["title"]}>Customer-Centric Services</p>
                {CCS.map((ccs) => (
                  <div className={styles["accordion-item"]} key={ccs["title"]}>
                    {/* <p className={styles["title"]}>{ccs.title}</p> */}
                    <p
                      className={styles["title"]}
                      dangerouslySetInnerHTML={{ __html: ccs.title }}
                    />
                    <p>{ccs.desc}</p>
                  </div>
                ))}
              </div>
              <div className={styles["main2"]}>
                <p className={styles["title"]}>
                  Marketing and Analysis Features
                </p>
                {MAF.map((maf) => (
                  <div className={styles["accordion-item"]} key={maf["title"]}>
                    {/* <p className={styles["title"]}>{maf.title}</p> */}
                    <p
                      className={styles["title"]}
                      dangerouslySetInnerHTML={{ __html: maf.title }}
                    />
                    <p>{maf.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles["section2"]}>
        <div className={styles["pricingTable-wrapper"]}>
          <div className={styles.pricingTable}>
            <h1 className={styles.heading}>Our Pricing</h1>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={`${styles.th} ${styles.th2}`}>
                    <div></div>
                    <div></div>
                    <div className={styles.supercharge}>
                      Enif Supercharge Features
                    </div>
                  </th>
                  <th className={`${styles.th} ${styles.starter}`}>
                    <span className={styles.head}>Starter</span>
                    <br />
                    <span className={styles.price}>$49.99</span>
                    <br />
                    <span className={styles.leg}>Manual Mode</span>
                  </th>
                  <th className={`${styles.th} ${styles.pro}`}>
                    <span className={styles.head}>Pro</span>
                    <br />
                    <span className={styles.price}>$399.99</span>
                    <br />
                    <span className={styles.leg}>Economy Mode</span>
                  </th>
                  <th className={`${styles.th} ${styles.premium}`}>
                    <span className={styles.head}>Premium</span>
                    <br />
                    <span className={styles.price}>$799.99</span>
                    <br />
                    <span className={styles.leg}>Efficiency Mode</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.trGray}>
                  <td className={styles.tdFeature}>Ticket Management</td>
                  <td className={styles.td}></td>
                  <td className={styles.td}></td>
                  <td className={styles.td}></td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Multi-Agent and Multi-Department Support
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Manual and Automatic Escalation
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Automated Assignment and Processing
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Ticket Processing Tiers</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Auto-Closed Tickets</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                {/* Add more rows as needed */}
                <tr className={styles.trGray}>
                  <td className={styles.tdFeature}>
                    Live Chat and Messaging Integration
                  </td>
                  <td className={styles.td}></td>
                  <td className={styles.td}></td>
                  <td className={styles.td}></td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Live Chat via Mobile App SDK
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Live Chat via Website Plugin
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Multi-Channel Messaging</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Automated Live Chat Support
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Escalation to Channels (Slack & Chat)
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                {/* Add more rows as needed */}
                <tr className={styles.trGray}>
                  <td className={styles.tdFeature}>
                    Customer-Centric Services
                  </td>
                  <td className={styles.td}></td>
                  <td className={styles.td}></td>
                  <td className={styles.td}></td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Customer-Centric Text Corrector
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Service Level Agreements (SLAs)
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Customer-Based SLA</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Multi-Level Escalations</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Time-Based Rules</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                {/* Add more rows as needed */}
                <tr className={styles.trGray}>
                  <td className={styles.tdFeature}>
                    Marketing and Analysis Features
                  </td>
                  <td className={styles.td}></td>
                  <td className={styles.td}></td>
                  <td className={styles.td}></td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Product Engagement Analysis
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Customer Sentiment Analysis
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Agent Performance Analysis
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Product Campaign Via SMS</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Product Campaign Via Email
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Product Campaign Via In-App
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Automated Email Support</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    AI Personalized Campaigns via Sales Pilot
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>Priority Support Queue</td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Advanced Analytics Dashboard
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tdFeature}>
                    Dedicated Account Manager
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.check}>&#10004;</span>
                  </td>
                  <td className={styles.td}>
                    <span className={styles.cross}>&#10008;</span>
                  </td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <div className={`${styles["accordion-section"]} ${styles["mobile"]}`}>
        <h2>Focus on Value Propositions</h2>
        {items.slice(0, 5).map((item, index) => (
          <Accordion
            key={index}
            style={{ borderRadius: "18px", overflow: "hidden" }}
            className={styles["accordion-items"]}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
              <Typography
                style={{ fontFamily: "Axiforma Bold", fontSize: "14px" }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ fontFamily: "Axiforma", fontSize: "12px" }}>
                {item.desc}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        <h2>Enif Supercharge Feature</h2>
        {items.slice(5).map((item, index) => (
          <Accordion
            key={index}
            style={{ borderRadius: "18px", overflow: "hidden" }}
            className={styles["accordion-items"]}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
              <Typography
                style={{ fontFamily: "Axiforma Bold", fontSize: "14px" }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ fontFamily: "Axiforma", fontSize: "12px" }}>
                {item.desc}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <PricingCards className={styles["mobile"]} />
      <section className={styles["section8"]}>
        <div className={styles["top"]}>
          <div className={styles["section8Header"]}>
            <h2>Automate your customer service with Enif today</h2>
          </div>
          <div className={styles["section8Description"]}>
            <p>
              Onboard, measure and coach your AI agent — like you would a human
              — and start resolving more inquiries, with less effort.
            </p>
          </div>
          <div className={styles["section8Buttons"]}>
            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["get-started-btn"]}
            >
              Get Started
            </a>

            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["learn-more-btn"]}
            >
              Learn More
            </a>
          </div>
        </div>
        <div className={styles["bottom"]}></div>
      </section>
    </div>
  );
};

export default Pricing;
