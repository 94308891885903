import { useState } from "react";
import { LeftPointerIcon, RightPointerIcon } from "../../assets/icons";
import Mike from "../../assets/images/Mike.png";
import BigCommerce from "../../assets/images/bigcommerce.svg";
import WooCommerce from "../../assets/images/woocommerce.svg";
import Magento from "../../assets/images/magento3.svg";
import OpenCart from "../../assets/images/opencart.svg";
import Wix from "../../assets/images/wix.svg";
import Shopify from "../../assets/images/shopify.svg";
import styles from "./RequestDemo.module.css";
import axios, { AxiosError, AxiosResponse } from "axios";

const RequestDemo = () => {
  const [firstName, setFirstName] = useState({
    error: "",
    value: "",
  });
  const [lastName, setLastName] = useState({ error: "", value: "" });
  const [companyName, setCompanyName] = useState({
    error: "",
    value: "",
  });
  const [jobTitle, setJobTitle] = useState({ error: "", value: "" });
  const [workEmail, setWorkEmail] = useState({ error: "", value: "" });
  const [companySize, setCompanySize] = useState({
    error: "",
    value: "",
  });
  const [companyType, setCompanyType] = useState({
    error: "",
    value: "",
  });
  const [challenge, setChallenge] = useState({ error: "", value: "" });
  const [formError, setFormError] = useState({
    error: "",
    success: "",
  });

  const companyTypes: string[] = [
    "Agriculture",
    "Automotive",
    "Banking",
    "Biotechnology",
    "Construction",
    "Consulting",
    "Consumer Goods",
    "Education",
    "Energy",
    "Entertainment",
    "Fashion",
    "Financial Services",
    "Food & Beverage",
    "Healthcare",
    "Hospitality",
    "Information Technology",
    "Insurance",
    "Legal",
    "Manufacturing",
    "Marketing",
    "Media",
    "Mining",
    "Non-Profit",
    "Pharmaceuticals",
    "Real Estate",
    "Retail",
    "Telecommunications",
    "Transportation",
    "Travel",
    "Utilities",
  ];

  const confirmFormHasValidInputs = () => {
    return (
      firstName.value &&
      lastName.value &&
      companyName.value &&
      jobTitle.value &&
      workEmail.value &&
      companySize.value &&
      companyType.value &&
      challenge.value
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = {
      firstName: firstName.value,
      lastName: lastName.value,
      companyName: companyName.value,
      jobTitle: jobTitle.value,
      workEmail: workEmail.value,
      companySize: companySize.value,
      companyType: companyType.value,
      challenges: challenge.value,
    };
    // fetch(`${process.env.REACT_APP_VITE_API_BASE_URL}/api/demo/request`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(formData),
    // })
    axios
      .post(
        `${process.env.REACT_APP_VITE_API_BASE_URL}/api/demo/request` ||
          `https://test.enif.ai/api/demo/request`,
        formData,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((data: AxiosResponse) => {
        // console.log("success", data);
        setFormError((prev) => ({
          ...prev,
          success: data.data.message || "Data submitted successfully",
          error: "",
        }));
        setFirstName((prev) => ({ ...prev, value: "" }));
        setLastName((prev) => ({ ...prev, value: "" }));
        setJobTitle((prev) => ({ ...prev, value: "" }));
        setCompanyName((prev) => ({ ...prev, value: "" }));
        setCompanySize((prev) => ({ ...prev, value: "" }));
        setCompanyType((prev) => ({ ...prev, value: "" }));
        setWorkEmail((prev) => ({ ...prev, value: "" }));
        setChallenge((prev) => ({ ...prev, value: "" }));
      })
      .catch((error: AxiosError | any) => {
        // console.log("error", error, error.response);
        setFormError((prev) => ({
          ...prev,
          error:
            error.response?.data?.message! ||
            "There was an error submitting the form",
          success: "",
        }));
      });
  };
  return (
    <div className={styles["wrapper"]}>
      <section id={styles["section-1"]}>
        <div className={styles["section-1_1"]}>
          <div className={styles["left"]}>
            <h1>Questions? We’ve Got Answers</h1>
            <p>
              Reach out to our team to see how your agency can benefit from
              Productive.
            </p>
          </div>
          <div className={styles["right"]}>
            <form className={styles["form"]} onSubmit={handleSubmit}>
              {formError.success && (
                <p className={styles["success-message"]}>
                  {formError.success.toLowerCase()}
                </p>
              )}
              {formError.error && (
                <p className={styles["error-message"]}>
                  {formError.error.toLowerCase()}
                </p>
              )}
              <div className={styles["form-field"]}>
                <div className={styles["input-field"]}>
                  <input
                    type="text"
                    id="firstName"
                    value={firstName?.value}
                    onFocus={(e) =>
                      setFirstName((prev: any) => {
                        return { ...prev, error: "" };
                      })
                    }
                    onBlur={(e) => {
                      setFirstName((prev: any) => {
                        if (!prev.value)
                          return { ...prev, error: "Enter first name" };
                        return { ...prev };
                      });
                    }}
                    onChange={(e) => {
                      setFirstName((prev: any) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }}
                    required
                  />
                  {firstName.value ? (
                    ""
                  ) : (
                    <label
                      className={styles["placeholder"]}
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                  )}
                  {firstName.error && (
                    <p className={styles["error-message"]}>{firstName.error}</p>
                  )}
                </div>
                <div className={styles["input-field"]}>
                  <input
                    type="text"
                    id="lastName"
                    value={lastName?.value}
                    onFocus={(e) =>
                      setLastName((prev: any) => {
                        return { ...prev, error: "" };
                      })
                    }
                    onBlur={(e) => {
                      setLastName((prev: any) => {
                        if (!prev.value)
                          return { ...prev, error: "Enter last name" };
                        return { ...prev };
                      });
                    }}
                    onChange={(e) => {
                      setLastName((prev: any) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }}
                    required
                  />
                  {lastName.value ? (
                    ""
                  ) : (
                    <label className={styles["placeholder"]} htmlFor="lastName">
                      Last Name
                    </label>
                  )}
                  {lastName.error && (
                    <p className={styles["error-message"]}>{lastName.error}</p>
                  )}
                </div>
              </div>
              <div className={styles["form-field"]}>
                <div className={styles["input-field"]}>
                  <input
                    type="text"
                    id="companyName"
                    value={companyName?.value}
                    onFocus={(e) =>
                      setCompanyName((prev: any) => {
                        return { ...prev, error: "" };
                      })
                    }
                    onBlur={(e) => {
                      setCompanyName((prev: any) => {
                        if (!prev.value)
                          return { ...prev, error: "Enter company name" };
                        return { ...prev };
                      });
                    }}
                    onChange={(e) => {
                      setCompanyName((prev: any) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }}
                    required
                  />
                  {companyName.value ? (
                    ""
                  ) : (
                    <label
                      className={styles["placeholder"]}
                      htmlFor="companyName"
                    >
                      Company Name
                    </label>
                  )}
                  {companyName.error && (
                    <p className={styles["error-message"]}>
                      {companyName.error}
                    </p>
                  )}
                </div>
                <div className={styles["input-field"]}>
                  <input
                    type="text"
                    id="jobTitle"
                    value={jobTitle?.value}
                    onFocus={(e) =>
                      setJobTitle((prev: any) => {
                        return { ...prev, error: "" };
                      })
                    }
                    onBlur={(e) => {
                      setJobTitle((prev: any) => {
                        if (!prev.value)
                          return { ...prev, error: "Enter job title" };
                        return { ...prev };
                      });
                    }}
                    onChange={(e) => {
                      setJobTitle((prev: any) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }}
                    required
                  />
                  {jobTitle.value ? (
                    ""
                  ) : (
                    <label className={styles["placeholder"]} htmlFor="jobTitle">
                      Job Title
                    </label>
                  )}
                  {jobTitle.error && (
                    <p className={styles["error-message"]}>{jobTitle.error}</p>
                  )}
                </div>
              </div>
              <div className={styles["form-field-1"]}>
                <div className={styles["input-field"]}>
                  <input
                    type="email"
                    id="workEmail"
                    value={workEmail?.value}
                    onFocus={(e) =>
                      setWorkEmail((prev: any) => {
                        return { ...prev, error: "" };
                      })
                    }
                    onBlur={(e) => {
                      setWorkEmail((prev: any) => {
                        const re =
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        let validEmail = re.test(
                          String(prev.value).toLowerCase()
                        );
                        if (!prev.value || !validEmail)
                          return { ...prev, error: "Enter valid work email" };
                        return { ...prev };
                      });
                    }}
                    onChange={(e) => {
                      setWorkEmail((prev: any) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }}
                    required
                  />
                  {workEmail.value ? (
                    ""
                  ) : (
                    <label
                      className={styles["placeholder"]}
                      htmlFor="workEmail"
                    >
                      Work Email
                    </label>
                  )}
                  {workEmail.error && (
                    <p className={styles["error-message"]}>{workEmail.error}</p>
                  )}
                </div>
              </div>
              <div className={styles["form-field"]}>
                <div className={styles["input-field"]}>
                  <select
                    onFocus={(e) =>
                      setCompanySize((prev: any) => {
                        return { ...prev, error: "" };
                      })
                    }
                    onBlur={(e) => {
                      setCompanySize((prev: any) => {
                        if (!prev.value)
                          return { ...prev, error: "Enter company size" };
                        return { ...prev };
                      });
                    }}
                    onChange={(e) => {
                      setCompanySize((prev: any) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Company Size </option>
                    <option value="1-9">1-9</option>
                    <option value="10-49">10-49</option>
                    <option value="50-249">50-249</option>
                    <option value="250+">250+</option>
                  </select>
                  {companySize.error && (
                    <p className={styles["error-message"]}>
                      {companySize.error}
                    </p>
                  )}
                </div>
                <div className={styles["input-field"]}>
                  <select
                    onFocus={(e) =>
                      setCompanyType((prev: any) => {
                        return { ...prev, error: "" };
                      })
                    }
                    onBlur={(e) => {
                      setCompanyType((prev: any) => {
                        if (!prev.value || prev.value === "")
                          return { ...prev, error: "Enter company type" };
                        return { ...prev };
                      });
                    }}
                    onChange={(e) => {
                      setCompanyType((prev: any) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Company Type </option>
                    {companyTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  {companyType.error && (
                    <p className={styles["error-message"]}>
                      {companyType.error}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles["form-field-1"]}>
                <textarea
                  id={styles["textarea"]}
                  placeholder="Tell us your challenges, requirements or ask us any question."
                  value={challenge?.value}
                  onFocus={(e) =>
                    setChallenge((prev: any) => {
                      return { ...prev, error: "" };
                    })
                  }
                  onBlur={(e) => {
                    setChallenge((prev: any) => {
                      if (!prev.value)
                        return {
                          ...prev,
                          error:
                            "Enter your challenges, requirements or questions",
                        };
                      return { ...prev };
                    });
                  }}
                  onChange={(e) => {
                    setChallenge((prev: any) => ({
                      ...prev,
                      value: e.target.value,
                    }));
                  }}
                  required
                />
                {challenge.error && (
                  <p className={styles["error-message"]}>{challenge.error}</p>
                )}
              </div>
              <div
                className={styles["form-field-1"]}
                id={styles["submit-field"]}
              >
                <button
                  type="submit"
                  className={styles["form-submit"]}
                  disabled={!confirmFormHasValidInputs()}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className={styles["cms"]}>
          <img src={BigCommerce} alt="BigCommerce" />
          <img src={WooCommerce} alt="WooCommerce" />
          <img src={Magento} alt="Magento" />
          <img src={OpenCart} alt="OpenCart" />
          <img src={Wix} alt="Wix" />
          <img src={Shopify} alt="Shopify" />
        </div>
      </section>
      <section className={styles["section7"]}>
        <h2>Businesses Succeeding with Enif</h2>
        <div className={styles["slider-control"]}>
          <div className={styles["left"]}>
            <p>The real power to unlock your business’ potential.</p>
          </div>
          <div className={styles["right"]}>
            <button>
              <LeftPointerIcon />
            </button>
            <button>
              <RightPointerIcon />
            </button>
          </div>
        </div>
        <div className={styles["slider"]}>
          <div className={styles["left"]}>
            <p>
              “Enif has been a game-changer for our small business. The
              onboarding process was a breeze, and the user-friendly interface
              made it easy for our team to get started. With Enif, we've
              streamlined our day-to-day operations, which means we're saving
              precious time and reducing operational costs. Enif also allows us
              to respond to our customers promptly, resulting in happier clients
              and a healthier bottom line. Enif has undeniably become a valuable
              member of our team.”
            </p>
            <p>Mike, COO of Briggs Store</p>
          </div>
          <div className={styles["right"]}>
            <img src={Mike} alt="Mike, COO of Briggs Store" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestDemo;
