import styles from "./Terms.module.css";

const Terms = () => {
  return (
    <div className={styles["terms"]}>
      <h1>Terms & Conditions</h1>
      <p>Effective Date: September 2023 </p>
      <p>
        Please review these Terms and Conditions ("Agreement") attentively
        before utilizing our services, as they embody crucial information
        concerning your legal rights, duties, and obligations. This Agreement
        constitutes a legal contract between you ("User," "you," or "your") and{" "}
        <span className={styles["head"]}>Neural Core Technologies Inc.</span>{" "}
        ("we," "us," "our," or "the Company") governing your utilization of our
        services.
      </p>
      <p>
        <span className={styles["head"]}>Acceptance of Terms</span>
        <br />
        By accessing or utilizing our services, you consent to be bound by this
        Agreement. If you dissent from any part of this Agreement, you must
        refrain from utilizing our services.
      </p>
      <div>
        <p className={styles["head"]}>1. Services</p>
        <p>
          <span className={styles["head"]}>1.1 Description: </span>Enif is an
          AI-powered middleware platform designed to automate business
          operations, encompassing sales analytics and customer service. It
          collates customer conversations from diverse channels and furnishes
          real-time responses. Enif seamlessly integrates with popular platforms
          and incorporates an escalation feature for expeditious resolution of
          critical issues. The precise details of the services, including any
          limitations or conditions, will be delineated on our website or other
          communication channels.
        </p>
        <p>
          <span className={styles["head"]}>1.2 Eligibility: </span> By utilizing
          our services, you affirm and warrant that you are at least 18 years
          old and possess the legal capacity to enter into this Agreement. If
          you access our services on behalf of an organization, you further
          affirm and warrant that you have the authority to bind that
          organization to this Agreement.
        </p>
        <p className={styles["head"]}>2. User Accounts</p>
        <p>
          <span className={styles["head"]}>2.1 Registration:</span>
          To access certain features or areas of our services, you may need to
          create a user account. You agree to furnish accurate, current, and
          complete information during the registration process and to maintain
          your account information updated. You are exclusively responsible for
          safeguarding the confidentiality of your account credentials and for
          all activities occurring under your account.
        </p>
        <p>
          <span className={styles["head"]}>2.2 Account Security: </span> You are
          obligated to implement suitable measures to uphold the security of
          your account and to promptly notify us of any unauthorized access or
          utilization of your account.
        </p>
        <p className={styles["head"]}>3. Intellectual Property</p>
        <p>
          <span className={styles["head"]}>3.1 Ownership: </span> The content,
          materials, and intellectual property rights linked with our services
          are owned by or licensed to the Company. You acknowledge that you do
          not acquire any ownership rights by utilizing our services.
        </p>
        <p>
          <span className={styles["head"]}>3.2 Restrictions: </span> You agree
          not to reproduce, modify, distribute, display, perform, or create
          derivative works of any content or materials from our services unless
          explicitly permitted by the Company.
        </p>
        <p className={styles["head"]}>4. User Responsibilities</p>
        <p>
          <span className={styles["head"]}>4.1 Compliance with Laws: </span> You
          agree to adhere to all applicable laws, regulations, and third-party
          rights while utilizing our services. You shall refrain from using our
          services for any unlawful or unauthorized purpose.
        </p>
        <p>
          <span className={styles["head"]}>4.2 Prohibited Activities: </span>{" "}
          You agree not to engage in any activities that might impede or disrupt
          the integrity or performance of our services, or infringe upon the
          rights of others. Prohibited activities include, but are not limited
          to, hacking, data scraping, unauthorized access, transmission of
          malware, or any other form of malicious or harmful behavior.
        </p>
        <p className={styles["head"]}>5. Privacy</p>
        <p>
          <span className={styles["head"]}>5.1 Privacy Policy: </span> Our
          collection, utilization, and disclosure of your personal information
          are governed by our Privacy Policy, which is incorporated by reference
          into this Agreement. By utilizing our services, you consent to our
          collection, utilization, and disclosure of your personal information
          as described in the Privacy Policy.
        </p>
        <p className={styles["head"]}>6. Limitation of Liability</p>
        <p>
          <span className={styles["head"]}>6.1 Disclaimer of Warranties: </span>{" "}
          Our services are provided on an "as-is" and "as available" basis,
          without any warranties or representations of any kind, whether express
          or implied. We do not guarantee the accuracy, completeness, or
          reliability of any content or materials provided through our services.
        </p>
        <p>
          <span className={styles["head"]}>6.2 Limitation of Liability: </span>{" "}
          To the fullest extent permitted by applicable law, we shall not be
          liable for any direct, indirect, incidental, consequential, or special
          damages arising out of or in connection with your utilization of our
          services, even if we have been advised of the possibility of such
          damages.
        </p>
        <p className={styles["head"]}>
          7. Governing Law and Dispute Resolution
        </p>
        <p>
          <span className={styles["head"]}>7.1 Governing Law: </span>This
          Agreement shall be governed by and construed in accordance with the
          Arbitration & Conciliation Act, Cap A18, Laws of the Federation of
          Nigeria (LFN) 2004, by a single arbitrator. Any disputes arising out
          of or in connection with this Agreement shall be subject to the
          exclusive jurisdiction of the courts located in Lagos, Nigeria and be
          conducted in English Language, with both Parties bearing the cost of
          arbitration equally.
        </p>
        <p className={styles["head"]}>8. Modifications to the Agreement</p>
        <p>
          <span className={styles["head"]}>8.1 Amendments: </span>
          We reserve the right to modify or amend this Agreement at any time.
          Any changes will be effective immediately upon posting the revised
          Agreement on our website or other communication channels. Your
          continued use of our services after the posting of any modifications
          constitutes your acceptance of the revised Agreement.
        </p>
        <p className={styles["head"]}>9. Contact Us</p>
        <p>
          If you have any questions, concerns, or feedback regarding this
          Agreement, please contact us at:
        </p>

        <p className={styles["head"]}>
          <address>
            Address: 16192 Coastal Highway, Lewes, Delaware 19958, County of
            Sussex
          </address>
          Email: <a href="mailto:info@enif.ai">info@enif.ai</a>
        </p>
        <p>
          We will respond to your inquiries as expeditiously as possible. By
          utilizing our services, you acknowledge that you have read,
          understood, and agree to be bound by this Agreement, including any
          additional terms and conditions referenced herein.
        </p>
      </div>
    </div>
  );
};
export default Terms;
