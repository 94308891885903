import styles from "./Privacy.module.css";

const Privacy = () => {
  return (
    <div className={styles["privacy"]}>
      <h1>Privacy Notice</h1>
      <p>Effective Date: September 2023 </p>
      <p>
        This Privacy Policy ("Policy") elucidates how{" "}
        <span className={styles["head"]}>Neural Core Technologies Inc.</span>{" "}
        collects, utilizes, discloses, and safeguards your personal information
        when you engage with our services. By accessing or utilizing our
        services, you agree to the collection and processing of your personal
        information as delineated in this Policy. Please peruse this Policy
        meticulously to apprehend our practices concerning your personal
        information.
      </p>
      <div>
        <p className={styles["head"]}>1. Information We Collect</p>
        <p>
          <span className={styles["head"]}>1.1 Personal Information: </span>{" "}
          When you register for Enif or avail our services, we may gather
          personal information that you directly provide to us, such as your
          name, email address, contact information, and any other information
          you voluntarily furnish while utilizing our services.
        </p>
        <p>
          <span className={styles["head"]}>1.2 Usage Data: </span> We may
          automatically amass certain information about your interaction with
          Enif and the features you utilize. This encompasses log data, your IP
          address, browser type, operating system, device information, and
          browsing activities. This information aids us in scrutinizing trends,
          administering our services, tracking users' movements, and compiling
          demographic information for aggregate utilization.
        </p>
        <p>
          <span className={styles["head"]}>1.3 Chatbot Data: </span> When you
          engage with the Enif Chatbot, we collect and retain the conversations
          between you and the Chatbot to enhance our services and furnish
          personalized responses.
        </p>
        <p className={styles["head"]}>2. How We Use Your Information</p>
        <p>
          <span className={styles["head"]}>
            2.1 Providing and Enhancing Services:
          </span>
          We employ your personal information to furnish you with the Enif
          services, tailor your experience, including customer support, and
          communicate with you regarding our services and updates.
        </p>
        <p>
          <span className={styles["head"]}>2.2 Analytics and Research: </span>{" "}
          We may utilize aggregated and anonymized information for analytical
          purposes, such as analyzing user behavior, trends, and preferences, to
          ameliorate our services and enhance user experience.
        </p>
        <p>
          <span className={styles["head"]}>2.3 Marketing Communications: </span>
          With your consent, we may dispatch you promotional emails,
          newsletters, or other communications about our services, special
          offers, or events. You can opt-out of receiving these communications
          at any time by following the instructions provided or contacting us
          directly.
        </p>
        <p>
          <span className={styles["head"]}> 2.4 Legal Compliance: </span>We may
          utilize and divulge your personal information to adhere to applicable
          laws, regulations, legal processes, or enforceable governmental
          requests, as necessary to safeguard our rights, privacy, safety, or
          property, as well as that of our users and the public, or as otherwise
          required or permitted by law.
        </p>
        <p className={styles["head"]}>3. Data Sharing and Disclosure</p>
        <p>
          <span className={styles["head"]}>3.1 Service Providers: </span> We may
          share your personal information with trusted third-party service
          providers who aid us in operating the Enif services, conducting
          business activities, or providing services on our behalf. These
          service providers are contractually obligated to handle your personal
          information securely and confidentially and are prohibited from
          utilizing it for any other purpose.
        </p>
        <p>
          <span className={styles["head"]}>3.2 Business Transfers: </span> In
          the event of a merger, acquisition, or sale of all or a portion of our
          assets, your personal information may be transferred to the acquiring
          entity. We will notify you via email or prominent notice on our
          website of any such change in ownership or control of your personal
          information.
        </p>
        <p>
          <span className={styles["head"]}>3.3 Legal Requirements: </span> We
          may divulge your personal information if required by law, court order,
          or government regulation, or if we believe that such disclosure is
          necessary to protect our rights, comply with a legal process, or
          respond to a lawful request.
        </p>
        <p className={styles["head"]}>4. Data Security</p>
        <p>
          We adopt reasonable measures to safeguard your personal information
          from unauthorized access, utilization, disclosure, alteration, or
          destruction. However, please note that no method of transmission over
          the internet or electronic storage is entirely secure, and we cannot
          guarantee absolute security of your information.
        </p>
        <p>
          <span className={styles["head"]}>4.2 Retention: </span> We retain your
          personal information for as long as necessary to fulfill the purposes
          outlined in this Privacy Policy, unless a longer retention period is
          required or permitted by law. When your personal information is no
          longer needed, we will securely delete or anonymize it.
        </p>
        <p className={styles["head"]}>5. Your Rights and Choices</p>
        <p>
          <span className={styles["head"]}>5.1 Access and Update: </span> You
          retain the right to access, correct, or update your personal
          information. You can accomplish this by logging into your account or
          contacting us directly. We will make reasonable efforts to accommodate
          your request, subject to any legal or contractual restrictions.
        </p>
        <p>
          <span className={styles["head"]}>5.2 Opt-out: </span> You can opt-out
          of receiving marketing communications from us by following the
          instructions provided or contacting us directly. However, please note
          that even if you opt-out of receiving marketing emails, we may still
          send you non-promotional communications related to your utilization of
          our services.
        </p>
        <p className={styles["head"]}>6. Children's Privacy</p>
        <p>
          Enif is not designed for use by individuals under the age of 18. We do
          not knowingly collect personal information from children. If we become
          aware that we have collected personal information from a child without
          parental consent, we will take steps to delete the information as soon
          as possible. If you believe that we may have collected personal
          information from a child, please contact us immediately.
        </p>
        <p className={styles["head"]}>7. Changes to this Privacy Policy</p>
        <p>
          We reserve the right to modify or update this Privacy Policy from time
          to time to reflect changes in our practices or for other operational,
          legal, or regulatory reasons. We will notify you of any material
          changes by posting the updated Privacy Policy on our website or
          through other appropriate communication channels. Your continued use
          of Enif after the effective date of the updated Privacy Policy
          constitutes your acceptance of the changes.
        </p>
        <p className={styles["head"]}>8. Contact Us</p>
        <p>
          If you have any questions, concerns, requests, or feedback regarding
          this Privacy Policy or the privacy practices of Enif, please contact
          us at:
        </p>
        <p className={styles["head"]}>
          Email: <a href="mailto:info@enif.ai">info@enif.ai</a>
        </p>
        <p>
          We will respond to your inquiries and requests as soon as reasonably
          possible. Thank you for taking the time to read our Privacy Policy. By
          using our services, you acknowledge that you have read, understood,
          and agree to be bound by this Policy, including any additional terms
          and conditions referenced herein. Your privacy is important to us, and
          we are committed to protecting your personal information and providing
          a secure and reliable experience with Enif.
        </p>
      </div>
    </div>
  );
};
export default Privacy;
