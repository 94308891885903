import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/home/Home";
import Privacy from "./pages/privacy/Privacy";
import Platform from "./pages/platform/Platform";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import RootLayout from "./pages/root/Root";
import Terms from "./pages/terms/Terms";
import Pricing from "./pages/pricing/Pricing";
import Channels from "./pages/channels/Channels";
import { useEffect } from "react";
import RequestDemo from "./pages/requestDemo/RequestDemo";
import Platform2 from "./pages/platform2/Platform2";
import Solution from "./pages/solution/Solution";
// import RedirectToFile from "./components/RedirectToFile/RedirectToSitemap";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        { path: "/hire-enif", element: <Platform /> },
        { path: "/enif-bi", element: <Platform2 /> },
        { path: "/privacy", element: <Privacy /> },
        { path: "/terms", element: <Terms /> },
        { path: "/request-demo", element: <RequestDemo /> },
        { path: "/pricing", element: <Pricing /> },
        { path: "/channels", element: <Channels /> },
        { path: "/solutions", element: <Solution /> },
        // {
        //   path: "/sitemap.xml",
        //   element: <RedirectToFile path="/sitemap.xml" />,
        // },
      ],
      errorElement: <PageNotFound />,
    },
  ]);
  // useEffect(() => {
  //   import("./enif-standalone-script.js")
  //     .then((enif) => {
  //       // Your bundled module is available here
  //       // You can use functions or variables from the module
  //       console.log(enif);
  //       enif.initialize(
  //         "3b9feb48-9dbc-4f05-a62c-aad63567502b239bd13a-c435-4934-a927-1fe6051f909a"
  //       );
  //     })
  //     .catch((error) => {
  //       console.error("Error loading the bundle:", error);
  //     });
  // }, []);
  return <RouterProvider router={router} />;
}

export default App;
