import channelHeaderImage from "../../assets/images/channelHeaderImage.svg";
// import ChannelsAutomate from "../../assets/images/ChannelsAutomate.svg";
import ChannelsAutomate from "../../assets/images/ChannelsAutomate.png";
import channelsSec3 from "../../assets/images/channels_sec3.svg";
import styles from "./Channels.module.css";
import {
  ChannelFacebook,
  ChannelGmail,
  ChannelTelegram,
  ChannelWhatsapp,
  ChannelInstagram,
  ChannelX,
  ChannelSomething,
  ChannelTwoHeads,
  ChannelHeadAndHeart,
  ChannelCheckers,
  ChannelTwoHeadsWhite,
  ChannelHeadAndHeartWhite,
  ChannelCheckersWhite,
} from "../../assets/icons";

const Channels = () => {
  return (
    <div className={styles["wrapper"]}>
      <section className={styles["section1"]}>
        <div className={styles["header"]}>
          <div className={styles["h2s"]}>
            <h2>Effortless Support,</h2>
            <h2>Everywhere Your</h2>
            <h2>Customers Are</h2>
          </div>
          <a href="/">Get Started</a>
          <div>
            <img src={channelHeaderImage} alt="enif" />
          </div>
        </div>
      </section>
      <section className={styles["section2"]}>
        <h2>Automate across all major</h2>
        <h2>messaging channels</h2>
        <p>Get started quickly! Once trained on your</p>
        <p>knowledge base, Enif deploys to all your channels</p>
        <div>
          <ChannelFacebook />
          <ChannelGmail />
          <ChannelTelegram />
          <ChannelWhatsapp />
          <ChannelInstagram />
          <ChannelX />
          <ChannelSomething />
        </div>
        <img src={ChannelsAutomate} alt="ChannelsAutomate" />
      </section>
      <section className={styles["section3"]}>
        <div>
          <div>
            <div>
              <ChannelTwoHeads />
              <ChannelTwoHeadsWhite />
              <h3>Meet Customers</h3>
              <h3> on Their Terms</h3>
              <p>
                Enif provides a seamless customer experience across social
                media, web, mobile, and email, all without needing extra staff.
              </p>
            </div>
            <div>
              <ChannelHeadAndHeart />
              <ChannelHeadAndHeartWhite />
              <h3>24/7 Self-Service for</h3>
              <h3>Happy Customers</h3>
              <p>
                Enif empowers customers to resolve common issues like account
                management or renewals on their own, boosting satisfaction and
                saving you costs.
              </p>
            </div>
            <div>
              <ChannelCheckers />
              <ChannelCheckersWhite />
              <h3>Resolve, Don't Just</h3>
              <h3>Respond</h3>
              <p>
                Enif goes beyond basic responses. It utilizes AI to understand
                and solve customer inquiries, not just manage them.
              </p>
            </div>
          </div>
          <div>
            <img src={channelsSec3} alt="livechat" />
          </div>
        </div>
      </section>
      <section className={styles["section8"]}>
        <div className={styles["top"]}>
          <div className={styles["section8Header"]}>
            <h2>Automate your customer service with Enif today</h2>
          </div>
          <div className={styles["section8Description"]}>
            <p>
              Onboard, measure and coach your AI agent — like you would a human
              — and start resolving more inquiries, with less effort.
            </p>
          </div>
          <div className={styles["section8Buttons"]}>
            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["get-started-btn"]}
            >
              Get Started
            </a>

            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["learn-more-btn"]}
            >
              Learn More
            </a>
          </div>
        </div>
        <div className={styles["bottom"]}></div>
      </section>
    </div>
  );
};

export default Channels;
