import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import Navigation from "../../components/Navigation/Navigation";
import styles from "./Root.module.css";
import { Outlet, ScrollRestoration } from "react-router-dom";

//root component
const RootLayout = () => {
  return (
    <>
      {/* <Modal /> */}
      <Navigation />
      <section className={styles["wrapper"]}>
        <div className={styles["main"]}>
          <ScrollRestoration />
          <Outlet />
        </div>
        <Footer />
      </section>
    </>
  );
};
export default RootLayout;
