import { useState } from "react";
import {
  PlatformsCustomerTrust,
  PlatformsHiddenInsights,
  PlatformsResolution,
} from "../../assets/icons";
import styles from "./Platform2.module.css";
import enifBI from "../../assets/images/enifBI.svg";
import measureWhatHappens from "../../assets/images/measureWhatHappens.svg";
import TrainEnif from "../../assets/images/train-enif.svg";
import EnifIntegrations from "../../assets/images/enif-integrations.svg";
import enifAnalysis from "../../assets/images/enifAnalysis.svg";

const Platform2 = () => {
  return (
    <div className={styles["platform"]}>
      <section className={styles["section1"]}>
        <div className={styles["header"]}>
          <div className={styles["h2s"]}>
            <h2>Uncover Hidden</h2>
            <h2>Insights with Enif BI</h2>
          </div>
          <a href="/">Get Started</a>
          <div>
            <img src={enifBI} alt="enif" />
          </div>
        </div>
      </section>
      <section className={styles["section2"]}>
        <div className={styles["section2-wrapper"]}>
          <div>
            <h2>Measure What</h2>
            <h2>Matters</h2>
            <p>Use Enif's insights to identify potential sales</p>
            <p>leads and reach them with targeted.</p>
            <p>campaigns.</p>
          </div>
          <div>
            <img src={measureWhatHappens} alt="measure-what-happens" />
          </div>
        </div>
      </section>
      <section className={styles["section3"]}>
        <div>
          <img src={enifAnalysis} alt="BI-Analysis" />
        </div>
        <div className={styles["sect3Grid"]}>
          <div>
            <PlatformsResolution />
            <h3>Go Beyond Resolutions</h3>
            <p>
              Enif analyses conversations to identify areas for improvement
              across your entire business, not just customer support.
            </p>
          </div>
          <div>
            <PlatformsCustomerTrust />
            <h3>Build Customer Trust</h3>
            <p>
              Enif helps you refine your knowledge base and identify common
              questions for FAQs, making your AI even smarter and more helpful.
            </p>
          </div>
          <div>
            <PlatformsHiddenInsights />
            <h3>Uncover Hidden Insights</h3>
            <p>
              Enif goes beyond resolving inquiries. It analyzes conversations to
              identify areas for improvement across your entire business.
            </p>
          </div>
        </div>
      </section>
      <section className={styles["section6"]}>
        <div className={styles["top"]}>
          <div className={styles["section6-top_texts"]}>
            <h2>Enif integrates with all your work tools</h2>
            <p>
              Connect Enif to dozens of ecommerce tools and platforms in less
              than 5 minutes.
            </p>
          </div>
          <img src={EnifIntegrations} alt="integrations" />
        </div>
      </section>
      <section className={styles["section7"]}>
        <div className={styles["left"]}>
          <h2 className={styles["header"]}>
            Train Enif Like a New Hire (It's Easy!)
          </h2>
          <p className={styles["description"]}>
            Set up Enif in minutes, no coding required. Train it on your
            knowledge base, and it'll tackle inquiries like a pro in no time.
          </p>
          <a
            href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
            target="_blank"
            rel="noreferrer"
          >
            <button>Learn more</button>
          </a>
        </div>
        <div className={styles["right"]}>
          <img src={TrainEnif} alt="Uncover Hidden Insights" />
        </div>
      </section>
      <section className={styles["section8"]}>
        <div className={styles["top"]}>
          <div className={styles["section8Header"]}>
            <h2>Automate your customer service with Enif today</h2>
          </div>
          <div className={styles["section8Description"]}>
            <p>
              Onboard, measure and coach your AI agent — like you would a human
              — and start resolving more inquiries, with less effort.
            </p>
          </div>
          <div className={styles["section8Buttons"]}>
            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["get-started-btn"]}
            >
              Get Started
            </a>

            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["learn-more-btn"]}
            >
              Learn More
            </a>
          </div>
        </div>
        <div className={styles["bottom"]}></div>
      </section>
    </div>
  );
};

export default Platform2;
