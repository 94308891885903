import styles from "./PageNotFound.module.css";
import { Link } from "react-router-dom";

export default function PageNotFound() {
    return (
        <div className={styles["pageNotFound"]}>
            <p>404</p>
            <p>Hey captain! Looks like you are heading the wrong way!</p>
            <Link to={"/"}><button>Take me back home</button></Link>
        </div>
    );
}
