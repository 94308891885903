import { useState } from "react";
import {
  ChannelCheckersWhite,
  ChannelHeadAndHeartWhite,
  EffortlessSupport,
  EffortlessSupportAcross,
  PersonalizedSupportMobileBlue,
  PlatformsCustomerTrust,
  PlatformsHiddenInsights,
  PlatformsResolution,
  SolutionAutomatedSupportDesktop,
  SolutionEffortlessOnboardingDesktop,
  ThreeHeads,
} from "../../assets/icons";
import styles from "./Solution.module.css";
import boostProduct from "../../assets/images/boostProduct.png";
import TrainEnif from "../../assets/images/train-enif.svg";
import EnifIntegrations from "../../assets/images/enif-integrations.svg";
import solutionsHeader from "../../assets/images/solutionsHeader.png";
import solutionFintech1 from "../../assets/images/solutionFintech1.svg";
import solutionFintech2 from "../../assets/images/solutionFintech2.svg";
import solutionFintech3 from "../../assets/images/solutionFintech3.png";
import solutionSME1 from "../../assets/images/solutionSME1.svg";
import solutionSME2 from "../../assets/images/solutionSME2.png";
import solutionSME3 from "../../assets/images/solutionSME3.svg";
import solutionFintechMobile1 from "../../assets/images/solutionFintechMobile1.svg";
import solutionFintechMobile2 from "../../assets/images/solutionFintechMobile2.png";
import solutionFintechMobile3 from "../../assets/images/solutionFintechMobile3.png";
import solutionSMEMobile1 from "../../assets/images/solutionSMEMobile1.svg";
import solutionSMEMobile2 from "../../assets/images/solutionSMEMobile2.png";
import solutionSMEMobile3 from "../../assets/images/solutionSMEMobile3.svg";

const Solution = () => {
  const [selectedSection, setSelectedSection] = useState("fintech");
  return (
    <div className={styles["platform"]}>
      <section className={styles["section1"]}>
        <div className={styles["header"]}>
          <div className={styles["h2s"]}>
            <h2>Enif for Businesses</h2>
          </div>
          <div className={styles["ps"]}>
            <p>
              We are dedicated to propelling the future of financial technology
              while
            </p>
            <p>
              supporting the vital growth of small and medium-sized enterprises
              (SMEs).
            </p>
          </div>
          <a href="/">Get Started</a>
          <div>
            <img src={solutionsHeader} alt="enif" />
          </div>
        </div>
      </section>
      <section className={styles["section2"]}>
        <div className={styles["section2-wrapper"]}>
          <div>
            <h2>Boost Product Use,</h2>
            <h2>Grow Accounts</h2>
            <p>Enif educates customers on product features and </p>
            <p>informs them about changes or promotions,</p>
            <p>encouraging them to explore your offerings.</p>
          </div>
          <div>
            <img src={boostProduct} alt="product-boost" />
          </div>
        </div>
      </section>
      <section className={styles["section3"]}>
        <div className={styles["section3-head-wrapper"]}>
          <h2>Empowering Fintech Innovations</h2>
          <h2>& SME</h2>
          <p>
            Our unique blend of expertise in both sectors ensures we provide
          </p>
          <p>tailored, cutting-edge solutions to help your business thrive.</p>
          <div>
            <button
              className={
                selectedSection.toLowerCase() === "fintech"
                  ? styles["active"]
                  : ""
              }
              onClick={() => setSelectedSection("fintech")}
            >
              Fintech
            </button>
            <button
              className={
                selectedSection.toLowerCase() === "sme" ? styles["active"] : ""
              }
              onClick={() => setSelectedSection("sme")}
            >
              SMEs
            </button>
          </div>
        </div>
        {selectedSection === "fintech" && (
          <div className={styles["switchingSection"]}>
            <div className={styles["switchingSection1"]}>
              <div className={styles["grid"]}>
                <div>
                  <SolutionEffortlessOnboardingDesktop />
                  <h3>
                    Effortless Onboarding,
                    <br /> Increased Adoption
                  </h3>
                  <p>
                    Enif guides customers through digital banking
                    <br /> setup, account funding, and applications,
                    <br /> making the process smooth and encouraging
                    <br /> them to use your services more.
                  </p>
                </div>
                <div>
                  <PlatformsCustomerTrust />
                  <h3>
                    Personalized Service,
                    <br /> Stronger Relationships
                  </h3>
                  <p>
                    Enif leverages customer data to deliver
                    <br /> personalized solutions. This builds trust and
                    <br /> strengthens customer relationships.
                  </p>
                </div>
                <div>
                  <SolutionAutomatedSupportDesktop />
                  <h3>
                    Automated Support,
                    <br /> Reduced Costs
                  </h3>
                  <p>
                    Enif handles customer inquiries across all
                    <br /> channels, securely answering basic questions
                    <br /> and providing information on loan
                    <br />
                    applications. This frees up your team and
                    <br /> saves on support costs.
                  </p>
                </div>
              </div>
              <img src={solutionFintech1} alt="fintech" />
            </div>
            <div className={styles["switchingSection2"]}>
              <div className={styles["switchingSection2Header"]}>
                <p>Clear comparison</p>
                <h3>
                  Enif AI vs.
                  <br /> Scripted Chatbots
                </h3>
              </div>
              <div className={styles["switchingSection2Images"]}>
                <img src={solutionFintech2} alt="limited-banking-chatbots" />
                <img src={solutionFintech3} alt="enif-ai-advantage" />
              </div>
            </div>
          </div>
        )}
        {selectedSection === "sme" && (
          <div className={styles["switchingSection"]}>
            <div className={styles["switchingSection1"]}>
              <img src={solutionSME1} alt="sme" />
              <div className={styles["grid"]}>
                <div>
                  <ThreeHeads />
                  <h3>
                    Effortless Onboarding,
                    <br /> Increased Adoption
                  </h3>
                  <p>
                    Enif guides customers through digital banking
                    <br /> setup, account funding, and applications,
                    <br /> making the process smooth and encouraging
                    <br /> them to use your services more.
                  </p>
                </div>
                <div>
                  <PlatformsCustomerTrust />
                  <h3>
                    Personalized Service,
                    <br /> Stronger Relationships
                  </h3>
                  <p>
                    Enif leverages customer data to deliver
                    <br /> personalized solutions. This builds trust and
                    <br /> strengthens customer relationships.
                  </p>
                </div>
                <div>
                  <EffortlessSupport />
                  <h3>
                    Automated Support,
                    <br /> Reduced Costs
                  </h3>
                  <p>
                    Enif handles customer inquiries across all
                    <br /> channels, securely answering basic questions
                    <br /> and providing information on loan
                    <br />
                    applications. This frees up your team and
                    <br /> saves on support costs.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles["switchingSection2"]}>
              <div className={styles["switchingSection2Header"]}>
                <p>endless relationship</p>
                <h3>
                  Deliver Personalized
                  <br />
                  Experiences 24/7
                </h3>
              </div>
              <div className={styles["switchingSection2Images"]}>
                <img src={solutionSME2} alt="limited-banking-chatbots" />
                <img src={solutionSME3} alt="enif-ai-advantage" />
              </div>
            </div>
          </div>
        )}
      </section>
      <section className={styles["section3_mobile"]}>
        <div className={styles["section3-head-wrapper_mobile"]}>
          <h2>Empowering Fintech Innovations</h2>
          <h2>& SME</h2>
          <p>
            Our unique blend of expertise in both sectors ensures we provide
          </p>
          <p>tailored, cutting-edge solutions to help your business thrive.</p>
          <div>
            <button
              className={
                selectedSection.toLowerCase() === "fintech"
                  ? styles["active"]
                  : ""
              }
              onClick={() => setSelectedSection("fintech")}
            >
              Fintech
            </button>
            <button
              className={
                selectedSection.toLowerCase() === "sme" ? styles["active"] : ""
              }
              onClick={() => setSelectedSection("sme")}
            >
              SMEs
            </button>
          </div>
        </div>
        {selectedSection === "fintech" && (
          <div className={styles.switchingContainer}>
            <section className={styles.section}>
              <img
                src={solutionFintechMobile1}
                alt="Chat interface"
                className={styles.image}
              />
            </section>

            <section className={styles.section}>
              <div className={`${styles.card} ${styles["padding-bottom"]}`}>
                <PersonalizedSupportMobileBlue />
                <h3>Effortless Onboarding, Increased Adoption</h3>
                <p>
                  Enif guides customers through digital banking setups, account
                  funding, and applications, making the process smooth and
                  encouraging them to use your services more.
                </p>
              </div>
              <div className={`${styles.card} ${styles["padding-bottom"]}`}>
                <ChannelHeadAndHeartWhite />
                <h3>Personalized Service, Stronger Relationships</h3>
                <p>
                  Enif leverages customer data to deliver personalized
                  solutions. This builds trust and strengthens customer
                  relationships.
                </p>
              </div>
              <div className={`${styles.card} ${styles["padding-bottom"]}`}>
                <ChannelCheckersWhite />
                <h3>Automated Support, Reduced Costs</h3>
                <p>
                  Enif handles customer inquiries across all channels, securely
                  answering basic questions and providing information on loan
                  applications. This frees up your team and saves on support
                  costs.
                </p>
              </div>
            </section>

            <section className={styles.section}>
              <h2>CLEAR COMPARISON</h2>
              <h3>Enif AI vs. Scripted Chatbots</h3>
              <div className={`${styles.card} ${styles.alignLeft}`}>
                <h4>LIMITED BANKING CHATBOTS</h4>
                <p>
                  Traditional chatbots follow scripts, limiting their ability to
                  handle complex inquiries.
                </p>
                <img
                  src={solutionFintechMobile2}
                  alt="Scripted Chatbots"
                  className={styles.image}
                />
              </div>
              <div
                className={`${styles.card} ${styles.black} ${styles.alignLeft}`}
              >
                <h4>ENIF'S AI ADVANTAGE</h4>
                <p>
                  Enif analyzes data from your systems to understand customer
                  needs and deliver effective solutions, going beyond scripted
                  responses.
                </p>
                <img
                  src={solutionFintechMobile3}
                  alt="Enif AI"
                  className={styles.image}
                />
              </div>
            </section>
          </div>
        )}

        {selectedSection === "sme" && (
          <div className={styles.switchingContainer}>
            <section className={styles.section}>
              <img
                src={solutionSMEMobile1}
                alt="Chat interface"
                className={styles.image}
              />
            </section>

            <section className={styles.section}>
              <div className={`${styles.card} ${styles["padding-bottom"]}`}>
                <PersonalizedSupportMobileBlue />
                <h3>Personalized Support, Engaged Customers</h3>
                <p>
                  Enif personalizes conversations and grows with your customers.
                  This keeps them engaged and reduces lost business.
                </p>
              </div>
              <div className={`${styles.card} ${styles["padding-bottom"]}`}>
                <ChannelHeadAndHeartWhite />
                <h3>Self-Service Solutions, Satisfied Customers</h3>
                <p>
                  Enif empowers customers to resolve common issues like account
                  management or renewals on their own, boosting satisfaction and
                  saving you costs.
                </p>
              </div>
              <div className={`${styles.card} ${styles["padding-bottom"]}`}>
                <EffortlessSupportAcross />
                <h3>Effortless Support Across Channels</h3>
                <p>
                  Enif integrates with your existing channels, tailoring
                  experiences to each customer's needs.
                </p>
              </div>
            </section>

            <section className={styles.section}>
              <h2>ENDLESS RELATIONSHIP</h2>
              <h3>Deliver Personalized Experiences 24/7</h3>
              <div className={`${styles.card} ${styles.alignLeft}`}>
                <h4>FROM ONBOARDING TO RENEWAL</h4>
                <p>
                  Enif provides personalized support throughout the customer
                  journey, making onboarding, account access, subscriptions, and
                  inquiries all easier for your customers.
                </p>
                <img
                  src={solutionSMEMobile2}
                  alt="Scripted Chatbots"
                  className={styles.image}
                />
              </div>
              <div className={`${styles.card} ${styles.alignLeft}`}>
                <h4>EFFORTLESS ONBOARDING</h4>
                <p>
                Enif simplifies product adoption with user guides and how-to's delivered directly by your AI agent
                </p>
                <img
                  src={solutionSMEMobile3}
                  alt="Enif AI"
                  className={styles.image}
                />
              </div>
            </section>
          </div>
        )}
      </section>
      <section className={styles["section6"]}>
        <div className={styles["top"]}>
          <div className={styles["section6-top_texts"]}>
            <h2>Enif integrates with all your work tools</h2>
            <p>
              Connect Enif to dozens of ecommerce tools and platforms in less
              than 5 minutes.
            </p>
          </div>
          <img src={EnifIntegrations} alt="integrations" />
        </div>
      </section>
      <section className={styles["section7"]}>
        <div className={styles["left"]}>
          <h2 className={styles["header"]}>
            Train Enif Like a New Hire (It's Easy!)
          </h2>
          <p className={styles["description"]}>
            Set up Enif in minutes, no coding required. Train it on your
            knowledge base, and it'll tackle inquiries like a pro in no time.
          </p>
          <a
            href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
            target="_blank"
            rel="noreferrer"
          >
            <button>Learn more</button>
          </a>
        </div>
        <div className={styles["right"]}>
          <img src={TrainEnif} alt="Uncover Hidden Insights" />
        </div>
      </section>
      <section className={styles["section8"]}>
        <div className={styles["top"]}>
          <div className={styles["section8Header"]}>
            <h2>Automate your customer service with Enif today</h2>
          </div>
          <div className={styles["section8Description"]}>
            <p>
              Onboard, measure and coach your AI agent — like you would a human
              — and start resolving more inquiries, with less effort.
            </p>
          </div>
          <div className={styles["section8Buttons"]}>
            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["get-started-btn"]}
            >
              Get Started
            </a>

            <a
              href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
              target="_blank"
              rel="noreferrer"
              className={styles["learn-more-btn"]}
            >
              Learn More
            </a>
          </div>
        </div>
        <div className={styles["bottom"]}></div>
      </section>
    </div>
  );
};

export default Solution;
