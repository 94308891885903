import { Link } from "react-router-dom";
import {
  FooterGoBackUpIcon,
  FacebookIcon2,
  FooterInstagramIcon,
  FooterTwitterIcon,
  FooterLinkedInIconBlack,
} from "../../assets/icons";
import LogoDark from "../../assets/images/enif-logo-black.svg";
import styles from "./Footer.module.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer>
      <section className={styles["top"]}>
        <div>
          <div className={styles["footer-enif"]}>
            <img src={LogoDark} alt="enif-logo" />
          </div>
          <div>
            <p>
              At Enif, we are dedicated to harnessing the power of AI
              responsibly. We pledge to uphold transparency, data privacy, and
              fairness in all our AI-driven processes.
            </p>
          </div>
          <div>
            <p>
              Our promise is to enable you, our users, to thrive while actively
              preventing AI abuse. Together, let's shape a future where
              technology truly works for the betterment of all.
            </p>
          </div>
        </div>
        <div>
          <div className={styles["solution"]}>
            <p>Platform</p>
            <ul>
              <li>
                <a href="/">Automate operations</a>
              </li>
              <li>
                <a href="/">Business Insight</a>
              </li>
              <li>
                <a href="/">Knowledge Base</a>
              </li>
              <li>
                <a href="/"> Transform Your Sales</a>
              </li>
              <li>
                <a href="/">Drive Efficiency</a>
              </li>
              <li>
                <a href="/">Apps Integrations</a>
              </li>
            </ul>
          </div>
          <div className={styles["solution"]}>
            <p>Channels</p>
            <ul>
              <li>
                <a href="/">Emails</a>
              </li>
              <li>
                <a href="/">Live-Chart</a>
              </li>
              <li>
                <a href="/">Instagram</a>
              </li>
              <li>
                <a href="/">Whatsapp</a>
              </li>
              <li>
                <a href="/">Facebook</a>
              </li>
              <li>
                <a href="/">Mobile Apps</a>
              </li>
            </ul>
          </div>
          <div className={styles["solution"]}>
            <p>Industries</p>
            <ul>
              <li>
                <a href="/">Financial Services</a>
              </li>
              {/* <li>
                <a href="/"> E-commerce</a>
              </li> */}
              <li>
                <a href="/">Education</a>
              </li>
              <li>
                <a href="/">Healthcare</a>
              </li>
              <li>
                <a href="/">Real Estate</a>
              </li>
              <li>
                <a href="/">Hospitality</a>
              </li>
              <li>
                <a href="/">Tourism</a>
              </li>
            </ul>
          </div>
          <div className={styles["solution"]}>
            <p>Pricing</p>
            <ul>
              <li>
                <a href="/">Starter (Monthly)</a>
              </li>
              <li>
                <a href="/">Pro (Monthly)</a>
              </li>
              <li>
                <a href="/">Premium (Monthly)</a>
              </li>
            </ul>
          </div>
          <div className={styles["solution"]}>
            <p>Solution</p>
            <ul>
              <li>
                <a href="/">For Fintech</a>
              </li>
              <li>
                <a href="/">For SMEs</a>
              </li>
              <li>
                <a href="/">For E-commerce</a>
              </li>
            </ul>
          </div>
          <div className={styles["solution"]}>
            <p>Legal</p>
            <ul>
              <li className={styles["pointer"]}>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li className={styles["pointer"]}>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li className={styles["pointer"]}>
                <Link to="#terms">Data privacy</Link>
              </li>
            </ul>
          </div>
          <div className={styles["solution"]}>
            <p>Request Demo</p>
            <ul>
              <li className={styles["pointer"]}>
                <Link to="/request-demo">
                  <button className={styles["demo"]}>Book a Demo</button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id="footer" className={styles["bottom"]}>
        <div>
          <a
            href="https://linkedin.com/company/enif-intelligence/?viewAsMember=true"
            target="_blank"
            rel="noreferrer"
          >
            <FooterLinkedInIconBlack color="black" />
          </a>
          <a
            href="#footer"
            // target="_blank"
            // rel="noreferrer"
          >
            <FacebookIcon2 color="black" />
          </a>
          <a
            href="https://www.instagram.com/enif_ai/"
            target="_blank"
            rel="noreferrer"
          >
            <FooterInstagramIcon color="black" />
          </a>
          <a
            href="https://twitter.com/enif_ai"
            target="_blank"
            rel="noreferrer"
          >
            <FooterTwitterIcon color="black" />
          </a>
        </div>
        <p>
          Neural Core Technologies Copyright &copy; {new Date().getFullYear()}.
          All rights reserved
        </p>
        <div onClick={scrollToTop}>
          <FooterGoBackUpIcon color="black" />
          Go back to top
        </div>
      </section>
    </footer>
  );
};

export default Footer;
