import { useEffect, useRef, useState } from "react";
import styles from "./Navigation.module.css";
import LogoLight from "../../assets/images/enif-logo-white.svg";
import { Link, NavLink } from "react-router-dom";

const Navigation = () => {
  const [scrolling, setScrolling] = useState(false);
  const [showNavBar, setShowNavBar] = useState(false);
  const navbarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const toggleNav = () => {
    setShowNavBar((prevState) => (prevState = !prevState));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target as Node)
    ) {
      setShowNavBar(false);
    }
  };
  return (
    <nav
      ref={navbarRef}
      className={`${styles["navbar"]} ${scrolling && styles["nav-shadow"]} ${
        scrolling && styles["navbar-transparent"]
      }`}
    >
      <div className={styles["navbar-logo"]}>
        {/* You can put an SVG or image here for the logo */}
        <Link to="/">
          <img src={LogoLight} alt="Enif-Logo" />
        </Link>
        <button id={styles["nav-toggle"]} onClick={toggleNav}>
          ☰
        </button>
      </div>
      <div
        className={`${styles["navbar-links"]} ${
          showNavBar ? styles["show"] : ""
        }`}
      >
        <ul>
          <li>
            <NavLink
              to="hire-enif"
              className={({ isActive }) => {
                return isActive ? styles["active"] : "";
              }}
            >
              Platform
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/solutions"
              className={({ isActive }) => {
                return isActive ? styles["active"] : "";
              }}
            >
              Solutions
            </NavLink>
          </li>
          <li>
            <NavLink
              to="channels"
              className={({ isActive }) => {
                return isActive ? styles["active"] : "";
              }}
            >
              Channels
            </NavLink>
          </li>
          <li>
            <NavLink
              to="pricing"
              className={({ isActive }) => {
                return isActive ? styles["active"] : "";
              }}
            >
              Pricing
            </NavLink>
          </li>
        </ul>
      </div>
      <div
        className={`${styles["navbar-auth"]} ${
          showNavBar ? styles["show"] : ""
        }`}
      >
        {/* <a
          href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/login`}
          target="_blank"
          rel="noreferrer"
          className={styles["login-link"]}
        >
          LOG IN
        </a> */}
        <a
          href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/signup`}
          target="_blank"
          rel="noreferrer"
          className={styles["get-started-btn"]}
        >
          Get Started
        </a>
      </div>
    </nav>
  );
};

export default Navigation;
