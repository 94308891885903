import React from "react";
import styles from "./PricingCards.module.css";
import { PricingChecker } from "../../assets/icons";

const pricingPlans = [
  {
    title: "Starter",
    price: "$49.99",
    description: "Manual mode",
    features: [
      {
        category: "Ticket Management",
        items: [
          "Multi-Agent and Multi-Department Support",
          "Manual and Automatic Escalation",
          "Automated Assignment and Processing",
        ],
      },
      {
        category: "Live Chat and Messaging Integration",
        items: [
          "Live Chat via Mobile App SDK",
          "Live Chat via Website Plugin",
          "Escalation to Channels (Slack & Chat)",
        ],
      },
    ],
  },
  {
    title: "Pro",
    price: "$399.99",
    description: "Economy mode",
    recommended: true,
    features: [
      {
        category: "Ticket Management",
        items: [
          "Multi-Agent and Multi-Department Support",
          "Manual and Automatic Escalation",
          "Automated Assignment and Processing",
          "Ticket Processing Tiers",
          "Auto-Closed Tickets",
        ],
      },
      {
        category: "Live Chat and Messaging Integration",
        items: [
          "Live Chat via Mobile App SDK",
          "Live Chat via Website Plugin",
          "Escalation to Channels (Slack & Chat)",
        ],
      },
      {
        category: "Customer-Centric Services",
        items: [
          "Customer-Centric Text Connector",
          "Service Level Agreements (SLAs)",
          "Customer-Based SLA",
          "Time-Based Rules",
        ],
      },
      {
        category: "Marketing and Analysis Features",
        items: [
          "Product Engagement Analysis",
          "Customer Sentiment Analysis",
          "Agent Performance Analysis",
          "Product Campaign Via Email",
          "Product Campaign Via In-App",
          "Advanced Analytics Dashboard",
          "Dedicated Account Manager",
        ],
      },
    ],
  },
  {
    title: "Premium",
    price: "$799.99",
    description: "Efficiency mode",
    features: [
      {
        category: "Ticket Management",
        items: [
          "Multi-Agent and Multi-Department Support",
          "Manual and Automatic Escalation",
          "Automated Assignment and Processing",
          "Ticket Processing Tiers",
          "Auto-Closed Tickets",
        ],
      },
      {
        category: "Live Chat and Messaging Integration",
        items: [
          "Live Chat via Mobile App SDK",
          "Live Chat via Website Plugin",
          "Escalation to Channels (Slack & Chat)",
        ],
      },
      {
        category: "Customer-Centric Services",
        items: [
          "Customer-Centric Text Connector",
          "Service Level Agreements (SLAs)",
          "Customer-Based SLA",
          "Time-Based Rules",
          "Multi-Level Escalations",
        ],
      },
      {
        category: "Marketing and Analysis Features",
        items: [
          "Product Engagement Analysis",
          "Customer Sentiment Analysis",
          "Agent Performance Analysis",
          "Product Campaign Via Email",
          "Product Campaign Via In-App",
          "Advanced Analytics Dashboard",
          "Dedicated Account Manager",
          "Product Campaign Via SMS",
          "Automated Email Support",
          "AI Personalized Campaigns via Sales Pilot",
          "Priority Support Queue",
        ],
      },
    ],
  },
];

const PricingCards = ({ className }: { className: any }) => {
  return (
    <div className={`${styles.pricingSection} ${className}`}>
      <h2>Our Pricing</h2>
      <div className={styles.pricingCards}>
        {pricingPlans.map((plan, index) => (
          <div key={index} className={styles.pricingCard}>
            <div className={styles.cardHeader}>
              <h3>{plan.title}</h3>
              <h4>{plan.price}</h4>
              <p className={styles.cardDescription}>{plan.description}</p>
              {plan.recommended && (
                <span className={styles.recommendedBadge}>Recommended</span>
              )}
            </div>
            {plan.features.map((feature, idx) => (
              <div key={idx} className={styles.featureCategory}>
                <h5>{feature.category}</h5>
                <ul>
                  {feature.items.map((item, idy) => (
                    <li key={idy}>
                      <PricingChecker />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingCards;
